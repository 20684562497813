<template>
  <div class="calendar__validation">
    <v-btn color="primary" @click="dialog.display = true" v-if="!user.validCalendar" depressed>
      {{ $t('generalCalendar.actions.buttons.calendarValidation') }}
    </v-btn>
    <confirm-dialog
        v-model="dialog.display"
        :title="$t('generalCalendar.actions.validationDialog.title')"
        :body="$t('generalCalendar.actions.validationDialog.body')"
        :has-action="true"
        @cancel="dialog.display = false"
        @valid="onValidCalendar"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CalendarValidation",

  components: {
    ConfirmDialog: () => import('@/components/Template/ConfirmDialog'),
  },

  data: () => ({
    dialog: {
      display: false,
      errorMessage: ''
    },
  }),

  computed: {
    ...mapGetters([
      'calendar',
      'user',
    ]),
  },

  methods: {
    ...mapActions([
      'loadUser',
      'updateUser',
      'displaySnackbar',
    ]),

    onValidCalendar() {
      this.updateUser({
        user: this.user,
        data: {'valid_calendar': true}
      })
          .then(() => {
            this.dialog.display = false;
            this.displaySnackbar({
              status: true,
              type: 'success',
              text: this.$t('generalCalendar.validated'),
              timeout: 10000,
            })
          })
    }
  }
}
</script>

<style scoped>
.calendar__validation {
  margin: 0px auto 20px auto;
  text-align: center;
}
</style>